<template>
  <b-modal
      id="form-record-catch-new-fisherman-modal"
      ref="form-record-catch-new-fisherman-modal"
      cancel-variant="outline-secondary"
      ok-title="Mentés"
      cancel-title="Mégsem"
      @ok="handleOk"
      centered
      title="Új horgász hozzáadása"
  >
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group
                label="Név"
            >
              <validation-provider
                  #default="{ errors }"
                  name="név"
                  rules="required"
                  vid="name"
              >
                <b-form-input
                    v-model="formData.name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="név"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
                label="Nemzetiség"
            >
              <validation-provider
                  #default="{ errors }"
                  name="nemzetiség"
                  rules="required"
                  vid="nationality"
              >
                <v-select
                    v-model="formData.nationality"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="nationalityOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <validation-provider
                #default="{ errors }"
                name="mező"
                vid="is_disabled"
            >
              <b-form-checkbox
                  v-model="formData.is_disabled"
                  :state="errors.length > 0 ? false:null"
              >
                Letiltva?
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import BCardCode from "@core/components/b-card-code";

export default{
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      role: 'record_catch',
      formData: {
        name: '',
        nationality: '',
        is_disabled:false,
      },
      nationalityOptions: [],
      required,
    }
  },
  created() {
    this.$store.dispatch('fetchNationalitiesForSelect').then(response => {
      this.nationalityOptions = response

      if (this.nationalityOptions.length) {
        this.formData.nationality = {
          value: 'HU',
          title: 'Magyarország'
        }
      }
    })
  },
  methods: {
    handleOk() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))
          fd.nationality = this.formData.nationality.value
          this.$store.dispatch('storeFisherman', fd).then(() => {
            this.$helpers.showSuccessToast()
            this.$emit('refresh-fishermen')
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
  },
}
</script>
