<template>
  <b-tab
      active
  >
    <template #title>
      <feather-icon
          icon="ImageIcon"
          size="21"
      />
      <strong>
        Képek
      </strong>
    </template>

    <div>
      <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          rounded="sm"
      >
        <b-row>
          <b-col cols="12" class="mb-3">
            <div class="mb-1">Csoportos képfeltöltéshez húzza rá, vagy kattintás után tallózza be a képeket a lenti
              beviteli mező segítségével.
            </div>
            <b-row>
              <b-col sm="9">
                <b-form-file
                    v-model="images"
                    placeholder="Válassz vagy húzd ide a fájlokat"
                    drop-placeholder="A fájlokat húzd ide ..."
                    accept="
                      image/bmp,
                      image/gif,
                      image/jpeg,
                      image/png,
                      image/webp
                      "
                    multiple
                />
              </b-col>
              <b-col sm="3">
                <b-button
                    v-if="images.length"
                    variant="danger"
                    class="float-left mr-1"
                    @click.prevent="() => {images = []}"
                >
                  <feather-icon
                      icon="Trash2Icon"
                      size="15"
                  />
                </b-button>
                <b-button
                    variant="primary"
                    type="submit"
                    :disabled="!images.length"
                    @click.prevent="submitFiles"
                >
                  Csoportos feltöltés
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <!-- képek renderelése -->
          <draggable
              v-model="tabData"
              class="list-group list-group-flush cursor-move d-block w-100"
              @change="onDragged"
          >
            <transition-group class="row">
              <b-col
                  v-for="(item, index) in tabData"
                  :key="item.image_id"
                  sm="2"
              >

                <b-card
                    class="image-card"
                    overlay
                    :img-src="item.publicUrl"
                    :title="item.image_name"
                    img-top
                >
                  <b-card-text>
                    <div class="mb-0">
                      <b-button
                          variant="link"
                          size="sm"
                          class="ml-1"
                          @click.prevent="deleteFile(item.image.file_id)"
                      >
                        <feather-icon
                            style="color: #DC3545"
                            icon="TrashIcon"
                            size="15"
                        />
                      </b-button>
                      <b-link
                          :href="item.downloadUrl"
                          target="_blank"
                      >
                        <b-button
                            variant="link"
                            size="sm"
                            class="ml-1"
                        >
                          <feather-icon
                              style="color: white"
                              icon="DownloadIcon"
                              size="15"
                          />
                        </b-button>
                      </b-link>
                    </div>
                  </b-card-text>
                </b-card>
              </b-col>
            </transition-group>
          </draggable>
        </b-row>
      </b-overlay>
    </div>

    <!--  add file modal  -->
    <b-modal
        id="add-file-modal"
        size="sm"
        :title="this.tmp.name"
        hide-footer
    >
<!--      <b-row class="mb-1">-->
<!--        <b-col>-->
<!--          <h6>Rövid útmutató</h6>-->
<!--          <p>-->
<!--            .... [lorem ...]-->
<!--          </p>-->
<!--        </b-col>-->
<!--      </b-row>-->
      <validation-observer ref="imageUploadValidationObserver">
        <b-form>
          <b-row>
            <b-col>
              <b-form-group
                  label="Kép"
              >
                <validation-provider
                    #default="{ errors }"
                    name="kép"
                    rules="required"
                    vid="title"
                >
                  <b-form-file
                      v-model="tmp.image"
                      :state="Boolean(tmp.image)"
                      placeholder="Tallózz ki, vagy húzz ide egy állományt"
                      drop-placeholder="Húzd ide ..."
                      accept="
                      image/bmp,
                      image/gif,
                      image/jpeg,
                      image/png,
                      image/webp
                      "
                  ></b-form-file>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <!--   close   -->
      <b-button variant="danger" class="mt-1 float-left" @click="closeModal('add-file-modal')">
        Bezárás
      </b-button>
      <!--   save   -->
      <b-button variant="success" class="mt-1 float-right" @click="sendImage">
        Mentés
      </b-button>
    </b-modal>
  </b-tab>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BTab,
  BBadge,
  BRow,
  BCol,
  BCardGroup,
  BCard,
  BCardText,
  BButton,
  BFormFile,
  BOverlay,
  BModal,
  BForm,
  BFormGroup,
  BLink
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTab,
    BBadge,
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BButton,
    BFormFile,
    BOverlay,
    BModal,
    BForm,
    BFormGroup,
    BLink,
    draggable,
  },
  directives: {
    Ripple,
  },
  props: {
    data: [],
    entityId: {
      type: Number,
      required: true
    },
    entityType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      tabData: [],
      dangerCnt: 0,
      images: [],
      required,
      tmp: {
        id: null,
        name: null,
        alt: '',
        image: null,
      },
    }
  },
  watch: {
    data: {
      handler(value) {
        this.tabData = value
        this.initTab()
      }
    }
  },
  computed: {
    hasLoading() {
      return this.loading
    }
  },
  created() {
    this.initTab()
  },
  methods: {
    initTab() {
      this.$store.dispatch('getImagesForEntity', {
        id: this.$route.params.id,
        type: this.entityType
      }).then(async response => {
        let tmp = []

        Object.keys(response.data.images).forEach(key => {
          tmp.push(response.data.images[key])
        })

        this.tabData = tmp

      }).catch(error => {
        if (error.response.status === 422) {
          this.$refs.simpleRules.setErrors(error.response.data.errors)
        } else {
          this.$helpers.showErrorToast(error.toString())
        }
      })
    },
    onDragged() {
      this.showLoadBar()
      let newOrder = []

      Object.keys(this.tabData).forEach(key => {
        newOrder.push(this.tabData[key].image_id)
      })

      this.$store.dispatch('orderImagesForEntity', {
        id: this.entityId,
        type: this.entityType,
        order: newOrder
      }).then(() => {
        this.$helpers.showSuccessToast()
      }).catch(error => {
        if (error.response.status === 500) {
          this.$helpers.showErrorToast(error.response.data.message)
        } else {
          this.$helpers.showErrorToast(error.toString())
        }
      }).finally(() => {
        this.hideLoadBar()
      })
    },
    submitFiles() {
      if (this.images.length) {
        this.showLoadBar()
        let all = 0
        let success = 0
        Object.keys(this.images).forEach(key => {

            let defaultLength = this.tabData.length

          const fd = new FormData();
          fd.append('modelId', this.$route.params.id)
          fd.append('modelType', this.entityType)
          fd.append('file', this.images[key])
          fd.append('index', parseInt(defaultLength)+parseInt(key))

          this.$store.dispatch('storeModelImage', fd).then(() => {
            success += 1
          }).catch(error => {
            if(error.response.status === 413){
              this.$helpers.showErrorToast('A feltölteni kívánt fájl(ok) túl nagy(ok).')
            }else if(error.response.status === 400){
              this.$helpers.showErrorToast(error.response.data.message)
            }else{
              this.$helpers.showErrorToast(error.toString())
            }
            this.hideLoadBar()
          }).finally(() => {
            all += 1
            if (success === this.images.length) {
              this.images = []
              this.$emit('refresh-data')
              this.$helpers.showSuccessToast()
              this.hideLoadBar()
            }

            if (all === this.images.length) {
              this.hideLoadBar()
            }
          })
        })
      }
    },
    uploadFileModal(imageName, alt) {
      this.tmp.name = imageName
      this.tmp.alt = alt
      this.$bvModal.show('add-file-modal')
    },
    sendImage() {
      this.$refs.imageUploadValidationObserver.validate().then(success => {
        if (success) {
          this.showLoadBar()
          const fd = new FormData()
          fd.append('customFileName', this.tmp.name)
          fd.append('modelId', this.$route.params.id)
          fd.append('modelType', this.entityType)
          fd.append('alt', this.tmp.alt)
          fd.append('file', this.tmp.image)

          this.$store.dispatch('storeModelImage', fd).then(() => {
            this.$bvModal.hide('add-file-modal')
            this.resetTempData()
            this.$emit('refresh-data')
            this.$helpers.showSuccessToast()
          }).catch(error => {
            if (error.response.status === 422) {
              this.$helpers.showErrorToast(error.response.data.errors[0])
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.hideLoadBar()
          })
        }
      })
    },
    deleteFile(fileId, showLoadBar = true) {
      this.$bvModal
          .msgBoxConfirm('Biztosan törölni szeretnéd a képet?', {
            title: 'Jóváhagyás',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Igen',
            cancelTitle: 'Nem',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              const fd = new FormData()
              fd.append('modelId', this.$route.params.id)
              fd.append('modelType', this.entityType)
              fd.append('fileId', fileId)

              if (showLoadBar) this.showLoadBar()
              // axios
              this.$store.dispatch('deleteFile', fd).then(() => {
                this.$emit('refresh-data')
                this.$helpers.showSuccessToast(`Sikeres törlés (${fileId})`)
              }).finally(() => {
                if (showLoadBar) this.hideLoadBar()
              })
            }
          })
    },
    // külön metódus kell, hogy kiüssük a tem datat is
    closeModal(modalName) {htop
      this.$bvModal.hide(modalName)
      this.resetTempData()
    },
    resetTempData() {
      this.tmp = {
        id: null,
        name: null,
        alt: '',
        image: null,
      }
    },
    showLoadBar() {
      this.loading = true
    },
    hideLoadBar() {
      this.loading = false
    },
  }
}
</script>

<style scoped>
  .image-card .card-img-overlay{
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .image-card .card-img-overlay .card-title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(66, 150, 142, 0.8);
    color: #FFFFFF;
  }

  .image-card .card-img-overlay .card-text{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);
    color: #FFFFFF;
  }

  .image-card .card-img-overlay .card-text{
    display: none;
  }

  .image-card:hover .card-img-overlay .card-text{
    display: flex;
  }
</style>
